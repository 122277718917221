<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">精品课程（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">精品课程列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div style="background: rgb(238, 216, 219); margin-bottom: 10px;">
          <div class="redDotBox">
            <el-badge  class="item" :value="redDotNum" :hidden="!redDot">
              <!-- <el-button size="small">课程价格变更通知</el-button> -->
              <!-- <icon class="el-icon-chat-dot-round" style="font-size: 20px">
            </icon> -->
              <span @click="priceNotice()">课程价格变更通知</span>
            </el-badge>
          </div>
        </div>
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                clearable
                placeholder="请输入课程名称"
              />
            </div>
            <div title="课程分类" class="searchboxItem ci-full">
              <span class="itemLabel">课程分类:</span>
              <el-cascader
                :options="miniCourseTypeList"
                v-model="courseType"
                :props="miniProps"
                size="small"
                clearable
              ></el-cascader>
            </div>
            <!-- <div title="是否推荐到首页" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8rem"
                >是否推荐到首页:</span
              >
              <el-select
                v-model="recommend"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
            <div title="是否上架" class="searchboxItem ci-full">
              <span class="itemLabel">是否上架:</span>
              <el-select
                v-model="salesState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in optionssalesState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >新增</el-button
              >
              <el-button class="bgc-bv" round @click="handleExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @cell-mouse-enter="M_Enter"
              @cell-mouse-leave="m_leave"
              @sort-change="changeSort"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="课程名称"
                align="center"
                prop="courseName"
                show-overflow-tooltip
                min-width="150"
              />

              <el-table-column
                label="课程分类"
                align="center"
                prop="courseTypeName"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="课程排序"
                align="center"
                prop="weight"
                  sortable="custom"
                show-overflow-tooltip
                min-width="150"
              >
                <template slot-scope="scope">
                  <div v-if="!scope.row.orderShow">
                    <span> {{ scope.row.weight || 0 }}</span>
                    <i
                      class="el-icon-edit"
                      v-show="scope.row.showInput"
                      @click="changeOrder(scope.row)"
                    ></i>
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.weight"
                    maxlength="1000"
                    size="small"
                    @blur="
                      () => {
                        editRowSort(scope.row);
                      }
                    "
                  >
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="小程序单价(元)"
                align="center"
                prop="miniPrice"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="苹果单价(元)"
                align="center"
                prop="iosPrice"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="安卓单价(元)"
                align="center"
                prop="androidPrice"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="Pc单价(元)"
                align="center"
                prop="pcPrice"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="管理后台价格(元)"
                align="center"
                prop="managePrice"
                show-overflow-tooltip
                min-width="120"
              />
              <el-table-column
                label="订单数量(个)"
                align="center"
                prop="orderNum"
                sortable="custom"
                show-overflow-tooltip
                min-width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.orderNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="人气"
                align="center"
                prop="studyNum"
                sortable="custom"
                show-overflow-tooltip
                min-width="110"
              />
              <el-table-column
                label="资源提供者"
                align="center"
                prop="resourceProviderName"
                sortable="custom"
                show-overflow-tooltip
                min-width="110"
              />
              <el-table-column
                label="播放量(次)"
                align="center"
                prop="playNum"
                sortable="custom"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.playNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="浏览量(次)"
                align="center"
                prop="browseNum"
                sortable="custom"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.browseNum || 0 }}
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="固定分享(人次)"
                align="center"
                prop="shareNum"
                sortable="custom"
                show-overflow-tooltip
                min-width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.shareNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="弹窗分享(人次)"
                align="center"
                prop="studyShareNum"
                sortable="custom"
                show-overflow-tooltip
                min-width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.studyShareNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="取消分享(人次)"
                align="center"
                prop="studyShareCancelNum"
                 sortable="custom"
                show-overflow-tooltip
                min-width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.studyShareCancelNum || 0 }}
                </template>
              </el-table-column> -->
              <el-table-column label="是否上架" align="center" max-width="150">
                <template slot-scope="scope">
                  {{ scope.row.salesState == "10" ? "未上架" : "已上架" }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="是否首页显示" align="center" width="120">
                <div slot-scope="scope" class="table-switch">
                  <el-switch
                    :width="20"
                    v-model="scope.row.recommend"
                    :active-value="true"
                    :inactive-value="false"
                    active-color="#13ce66"
                    @change="
                      (val) => {
                        handleStu(
                          scope.row.recommend,
                          scope.row.courseId,
                          scope.$index
                        );
                      }
                    "
                  ></el-switch>
                </div>
              </el-table-column> -->
              <el-table-column label="创建时间" align="center" width="120"    prop="createTime" sortable="custom">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.createTime | momentDate }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="260px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="() => handleCreate('edit', scope.row.courseId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    v-if="scope.row.allowUpdateSalesState"
                    @click.stop="
                      () =>
                        shelf(
                          scope.row.salesState,
                          scope.row.courseId,
                          scope.row,
                        )
                    "
                    >{{
                      scope.row.salesState == "10" ? "上" : "下"
                    }}架</el-button
                  >
                  <el-popover
                    placement="left-start"
                    width="200"
                    trigger="hover"
                    :content="
                      '/pages/myClassVideo/myClassVideo?courseid=' +
                      scope.row.courseId
                    "
                  >
                    <el-button type="text" slot="reference">查看链接</el-button>
                  </el-popover>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="seeShare(scope.row)"
                    >查看分享码</el-button
                  >

                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    :disabled="scope.row.allowDeleteState"
                    @click="deleteCourse(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 查看分享吗的弹框 -->
    <el-dialog
      :title="seeShareData.title + '-分享码'"
      :visible.sync="seeShareDialog"
      width="30%"
    >
      <img
        id="parter"
        style="width: 60%; display: block; margin: auto"
        :src="seeShareData.url"
        alt=""
      />
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="bgc-bv"
          size="small"
          round
          @click="downloadShare"
          >下载小程序码</el-button
        >
      </span>
    </el-dialog>
    <!-- 价格变更通知 -->
    <el-dialog
      title="价格变更通知"
      :visible.sync="priceNoticeDialog"
      width="800px"
      top="1%"
      @close="priceNoticeClose"
      class="dialogPrice"
    >
      <div class="priceNotice">
        <div class="priceTime ovy-a" v-infinite-scroll="load">
          <div
            v-for="(item, index) in priceNoticeList"
            :key="index"
            class="priceTimeContent"
            :class="active == index ? 'priceTimeContentTrue' : ''"
            @click="priceTimeContent(item.ksbCourseOutsideNoticeId, index)"
          >
            <!-- <el-badge :value="item.ksbCourseOutsideNoticeNum" class="item"> -->

            <p style="display: flex">
              {{ item.ksbCourseOutsideNoticeName }}
              <span
                class="priceDotNum"
                v-show="item.ksbCourseOutsideNoticeState != '20'"
                >{{ item.ksbCourseOutsideNoticeNum }}</span
              >
            </p>
            <!-- </el-badge> -->
          </div>
          <!-- <div class="pageNum">
            <el-pagination
              :total="fenYe.total"
              :page-size="fenYe.size"
              :current-page="fenYe.currentPage"
              background
              layout="total, prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div> -->
        </div>
        <div class="priceContent ovy-a">
          <div v-for="(item, index) in CourseNoticeContent" :key="index">
            <el-form ref="form" :model="form" label-width="120px">
              <el-form-item label="课程名称：">
                <p>{{ item.ksbCourseBaseName }}</p>
              </el-form-item>
              <el-form-item label="价格变更前：">
                <p>￥{{ item.ksbCourseBasePrice }}</p>
              </el-form-item>
              <el-form-item label="价格变更后：">
                <p style="color: #FF6B4B">￥{{ item.ksbCourseBaseChangePrice }}</p>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 课程上架提示 -->
    <el-dialog
      title="课程上架"
      :visible.sync="dialogShow"
      :close-on-click-modal="false"
      append-to-body
      width="600px">
      <div>
        <div class="dfOn">是 否 确 认 上 架 ？</div>
        <el-divider />
        <div class="dialogTit">价格信息：<span v-if="dialogData.freeState == '20'">&nbsp;&nbsp;&nbsp;&nbsp;免费</span></div>
        <div class="dialogContent">
          <el-form v-if="dialogData.freeState == '10'" ref="form" :model="dialogData" label-width="150px">
            <el-row>
              <el-col :span="12"><el-form-item label="小程序单价："><span style="font-size:15px">￥</span>{{dialogData.miniPrice}}</el-form-item></el-col>
              <el-col :span="12"><el-form-item label="安卓单价："><span style="font-size:15px">￥</span>{{dialogData.androidPrice}}</el-form-item></el-col>
            </el-row>
            <el-row>
              <el-col :span="12"><el-form-item label="苹果单价："><span style="font-size:15px">￥</span>{{dialogData.iosPrice}}</el-form-item></el-col>
              <el-col :span="12"><el-form-item label="管理后台单价："><span style="font-size:15px">￥</span>{{dialogData.managePrice}}</el-form-item></el-col>
            </el-row>
            <el-row>
              <el-col :span="12"><el-form-item label="PC单价："><span style="font-size:15px">￥</span>{{dialogData.pcPrice}}</el-form-item></el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="goodsOn">确 认 上 架</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      courseName: "",
      courseType: "",
      recommend: "",
      courseOrigin: "",
      salesState: "",
      dialogFormVisible: false,
      options: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      optionssalesState: [
        {
          value: "10",
          label: "下架",
        },
        {
          value: "20",
          label: "上架",
        },
      ],
      miniCourseTypeList: [], //培训类型
      miniProps: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
      //  二维码弹框数据
      seeShareData: {
        url: "", // 二维码路径
        title: "", // 弹框标题
      },
      // 二维码弹框 - 状态
      seeShareDialog: false,
      //排序字段
      orderNumSort: "",
      studyNumSort: "",
      playNumSort: "",
      browseNumSort: "",
      shareNumSort: "",
      studyShareNumSort: "",
      studyShareCancelNumSort: "",
      weightSort: "",
      createTimeSort: "",
      redDot: false, //红点显示判断
      priceNoticeDialog: false, //价格变更通知
      // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 10,
        total: null, // 共多少页
      },
      priceNoticeList: [], //变更时间数（左）
      CourseNoticeContent: [], //变更详情数据（右）
      active: "-1",
      hasNextPage: false, //是否有下一页
      redDotNum:0,
      dialogShow:false,//上架弹窗提示
      dialogData:{},//上架弹窗数据
    };
  },
   computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getTableHeight();
    this.getcourseTypeArrData();
  },
  methods: {
    // 鼠标进入事件
    M_Enter(row, column, cell, event) {
      if (column.label == "课程排序") {
        row.showInput = true;
      }
    },
    // 鼠标离开事件
    m_leave(row, column, cell, event) {
      row.showInput = false;
    },
    // 点击笔
    changeOrder(row) {
      row.orderShow = !row.orderShow;
    },
    //输入框失去焦点 保存修改
    editRowSort(row) {
      let url = "/minicourse/operation/modifyWeight";
      this.formatValue(row.weight);
      const params = {
        weight: this.value,
        courseId: row.courseId,
      };
      this.$post(url, params, 3000, true, 2).then((res) => {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getData(-1);
      });
    },
    formatValue(val) {
      val = val.replace(/(^\s*)|(\s*$)/g, "");
      if (!val) return (this.value = "");
      val = val.replace(/[^\d.]/g, "");
      val = val.replace(/^\./g, "");
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      this.value = val;
    },
    handleListDataBack(data) {
      this.tableData = [];
      data.map((el) => {
        el.showInput = false;
        el.orderShow = false;
        this.tableData.push(el);
      });
    },
    //获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { hiddenState: "10" },
        3000,
        true,
        2
      ).then((ret) => {
        this.miniCourseTypeList = this.delchild(ret.data);
      });
    },
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    //排序chang事假
    changeSort(row, clounm) {
      //订单排序
      if (row.prop == "orderNum") {
        this.orderNumSort =
          row.order == "ascending"
            ? "2"
            : row.order == "descending"
            ? "1"
            : row.order;
        this.studyNumSort = "";
        this.playNumSort = "";
        this.browseNumSort = "";
         this.weightSort = "";
         this.createTimeSort = "";
      }
       //学习人数排序
      if (row.prop == "studyNum") {
        this.studyNumSort =
          row.order == "ascending"
            ? "2"
            : row.order == "descending"
            ? "1"
            : row.order;
        this.orderNumSort = "";
        this.playNumSort = "";
        this.browseNumSort = "";
        this.weightSort = "";
         this.createTimeSort = "";
      }
      //播放量排序
      if (row.prop == "playNum") {
        this.playNumSort =
          row.order == "ascending"
            ? "2"
            : row.order == "descending"
            ? "1"
            : row.order;
        this.orderNumSort = "";
        this.studyNumSort = "";
        this.browseNumSort = "";
         this.weightSort = "";
         this.createTimeSort = "";
      }
      //浏览量排序
      if (row.prop == "browseNum") {
        this.browseNumSort =
          row.order == "ascending"
            ? "2"
            : row.order == "descending"
            ? "1"
            : row.order;
        this.orderNumSort = "";
        this.studyNumSort = "";
        this.playNumSort = "";
          this.weightSort = "";
         this.createTimeSort = "";
      }
    //课程排序
      if (row.prop == "weight") {
        this.weightSort =
          row.order == "ascending"
            ? "2"
            : row.order == "descending"
            ? "1"
            : row.order;
        this.orderNumSort = "";
        this.studyNumSort = "";
        this.browseNumSort = "";
        this.playNumSort = "";
        this.createTimeSort = "";
      }
      //创建时间排序
      if (row.prop == "createTime") {
        this.createTimeSort =
          row.order == "ascending"
            ? "2"
            : row.order == "descending"
            ? "1"
            : row.order;
        this.orderNumSort = "";
        this.studyNumSort = "";
        this.browseNumSort = "";
        this.playNumSort = "";
        this.weightSort = "";
      }
      this.getData(-1);
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseName: this.courseName,
        // recommend: this.recommend,
      };
      if (this.courseType) {
        params.courseType = this.courseType;
      }
      if (this.courseOrigin) {
        params.courseOrigin = this.courseOrigin;
      }
      if (this.salesState) {
        params.salesState = this.salesState;
      }
      /* 排序参数 */
      if (this.orderNumSort) {
        params.orderNumSort = this.orderNumSort;
      }
      if (this.studyNumSort) {
        params.studyNumSort = this.studyNumSort;
      }
      if (this.playNumSort) {
        params.playNumSort = this.playNumSort;
      }
      if (this.browseNumSort) {
        params.browseNumSort = this.browseNumSort;
      }
      if (this.weightSort) {
        params.weightSort = this.weightSort;
      }
      if (this.createTimeSort) {
        params.createTimeSort = this.createTimeSort;
      }
      // if (this.shareNumSort) {
      //   params.shareNumSort = this.shareNumSort;
      // }
      // if (this.studyShareNumSort) {
      //   params.studyShareNumSort = this.studyShareNumSort;
      // }
      // if (this.studyShareCancelNumSort) {
      //   params.studyShareCancelNumSort = this.studyShareCancelNumSort;
      // }
      this.doFetch(
        {
          url: "/minicourse/operation/list",
          params,
          pageNum,
        },
        true,
        2
      );
      this.getRedDot();
    },
    //新增
    handleCreate(stu, courseId = "") {
      this.$router.push({
        path: "/web/miniApp/createplatformCouerse",
        query: { courseId, stu },
      });
    },
    // 是否首页显示
    // handleStu(recommend, courseId, index) {
    //   this.$post(
    //     "/minicourse/operation/modifyRecommend",
    //     {
    //       recommend,
    //       courseId,
    //     },
    //     3000,
    //     true,
    //     2
    //   )
    //     .then((res) => {
    //       if (res.status == "0") {
    //         this.$message({
    //           type: "success",
    //           message: "修改成功",
    //         });
    //         this.getData(-1);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err.data.status);
    //       if (err.data.status == "-1") {
    //         this.tableData[index].recommend = false;
    //       }
    //     });
    // },
    //查看学员
    handlelookStudent(courseName, courseId) {
      this.courseId = courseId;
      this.$router.push({
        path: "/web/miniApp/myResourceStudentList",
        query: { courseName, courseId },
      });
    },
    // 上下架
    shelf(salesState, courseId, row) {
      this.courseId = courseId;
      if (salesState === "10") {
        this.dialogShow = true
        this.dialogData = row
      } else {
        this.$post(
          "/minicourse/updateSalesStateOff",
        {
          courseId,
        },
        3000,
        true,
        2
      )
        .then((res) => {
          if (res.status == "0") {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.getData(-1);
          }
        })
        .catch((err) => {});
      }
    },
    // 上架
    goodsOn(){
      this.$post(
          "/minicourse/updateSalesStateOn",
          {
            courseId:this.courseId,
          },
          3000,
          true,
          2
        )
          .then((res) => {
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: "上架成功",
              });
              this.getData(-1);
              this.dialogShow = false
              this.dialogData = {}
            }
          })
          .catch((err) => {});
      
    },
    handleSetWeight(courseId, weight) {
      this.DialogVisible = true;
      this.courseId = courseId;
      this.Form.weightNum = weight;
    },
    // 查看分享吗
    seeShare(row) {
      this.seeShareData.title = row.courseName;
      this.$post(
        "/minicourse/getQrCode",
        {
          courseId: row.courseId,
        },
        3000,
        true,
        2
      )
        .then((res) => {
          if (res.status == "0") {
            this.seeShareData.url = res.message;
            this.seeShareDialog = true;
          }
        })
        .catch((err) => {});
    },
    // 下载小程序码
    downloadShare() {
      window.open(this.seeShareData.url);
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    //获取红点
    getRedDot() {
      this.$post("/biz/ksb/ksbCourse/allRedDot", {}).then((res) => {
        this.redDot = res.data.redDot;
        this.redDotNum = res.data.num;
      });
    },
    load() {
      if (this.hasNextPage) {
        this.fenYe.currentPage += 1;
        this.priceNotice(this.fenYe.currentPage, this.fenYe.size);
      }
    },
    //获取拉取时间
    priceNotice(page = 1, pagesize = this.fenYe.size) {
      this.priceNoticeDialog = true;
      let parmars = {
        pageNum: page,
        pageSize: pagesize,
      };
      this.$post("/biz/ksb/ksbCourse/courseOutsideNoticeList", parmars).then(
        (res) => {
          this.priceNoticeList = [...this.priceNoticeList,...res.data.list];
          this.fenYe.total = res.data.total;
          this.hasNextPage = res.data.hasNextPage;
        }
      );
    },
   
    //获取拉取课程变更详情
    priceTimeContent(ksbCourseOutsideNoticeId, index) {
      this.$post("/biz/ksb/ksbCourse/courseOutsideNoticeListInfo", {
        ksbCourseOutsideNoticeId,
      }).then((res) => {
        this.active = index;
        this.CourseNoticeContent = res.data || [];
        this.priceNoticeList[index].ksbCourseOutsideNoticeState = '20'
      });
    },
    //价格变更关闭
    priceNoticeClose() {
      this.priceNoticeDialog = false;
      this.fenYe.currentPage = 1;
      this.active = "-1";
      this.priceNoticeList = [];
      this.CourseNoticeContent = [];
      this.getRedDot()
    },
    // 删除精品课
    deleteCourse(row){
        const {courseId}=row;
        this.$confirm('此操作将永久删除该课程, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass:"confirmButton-feifan",
          type: 'warning'
        }).then(async () => {
            await this.$post("/minicourse/deleteCourse",{courseId},3000,true,2);
            this.getData(-1);
            this.$message.success("操作成功");
        })    
    },
    //导出
    handleExport() {
        const params = {
        courseName: this.courseName,
        // recommend: this.recommend,
      };
      if (this.courseType) {
        params.courseType = this.courseType;
      }
      if (this.courseOrigin) {
        params.courseOrigin = this.courseOrigin;
      }
      if (this.salesState) {
        params.salesState = this.salesState;
      }
      /* 排序参数 */
      if (this.orderNumSort) {
        params.orderNumSort = this.orderNumSort;
      }
      if (this.studyNumSort) {
        params.studyNumSort = this.studyNumSort;
      }
      if (this.playNumSort) {
        params.playNumSort = this.playNumSort;
      }
      if (this.browseNumSort) {
        params.browseNumSort = this.browseNumSort;
      }
      if (this.weightSort) {
        params.weightSort = this.weightSort;
      }
      if (this.createTimeSort) {
        params.createTimeSort = this.createTimeSort;
      }
      this.$post('/minicourse/operationList/export',params,3000,true,2).then(res => {
 if (res.status == "0") {
          let list = res.data;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
      })
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == "true") {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style>
    .confirmButton-feifan{
        background-color:#5c6be8 !important;
        color:white;
        border:none;
    }
</style>
<style lang="less" scoped>
.redDotBox {
  width: 8rem;
  margin-left: 13px;
  padding: 19px 0;
  color: #d90119;
  cursor: pointer;
  /deep/ .el-badge__content.is-dot {
    height: 8px !important;
  }
}

.priceNotice {
  display: flex;
  height: 500px;
  // border: 1px solid #c3c3c3;
  .priceTime {
    width: 260px;
    position: relative;
background: #F5F5F5;
padding:0;
    .priceTimeContent {
      padding: 17px 23px;
      cursor: pointer;
      color:#333333;
      .priceDotNum {
        width: 30px;
        color: #fff;
        background: #FF6B4B;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
    }
    .priceTimeContentTrue {
      background: #FAFAFA;
      color:#666666;
    }
    .pageNum {
      position: absolute;
      bottom: 10px;
      right: 0;
    }
  }
  .priceContent {
    flex: 1;
    padding: 20px;
    background:#FAFAFA;
    > div {
      background: #FFFFFF;
      margin-bottom: 20px;
      border-radius: 3px;
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
.dialogPrice /deep/ .el-dialog__body {
  padding:0;
  background: #FAFAFA;
}
/deep/ .dfOn{
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 18px;
}
/deep/ .dialogTit{
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 15px;
}
/deep/ .dialogContent .el-form-item{
  margin-bottom: 10px;
  color: #f56c6c;
}
::-webkit-scrollbar  {
  width: 0;
}
::-webkit-scrollbar-thumb {
  background: transparent;
  width:0;
}
</style>
<style lang="less" scoped></style>
